import styled from 'styled-components';
import {
  ButtonBase,
  PrimaryButton,
  borderRadius,
  neutral0,
  fontWeightRegular,
  spacing4,
} from 'lib/mortar';
import { colorTextSecondary } from 'lib/design-system';

export const Wrapper = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${neutral0};
  height: 50px;
  border-radius: ${borderRadius(1)};
  overflow: hidden;

  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 0;

  ${PrimaryButton} {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    width: 32%;
    max-width: 166px;
  }
`;

const Label = styled.span`
  padding-left: ${spacing4};
  color: ${colorTextSecondary};
  font-weight: ${fontWeightRegular};
`;

const SingleFieldSearchForm = ({
  showSearchModal,
}: {
  showSearchModal: () => void;
}) => (
  <Wrapper tabIndex={0} onClick={showSearchModal}>
    <Label>Trade (e.g. Electrician)</Label>
    <PrimaryButton as="span">Search</PrimaryButton>
  </Wrapper>
);

export default SingleFieldSearchForm;
